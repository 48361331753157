const privacyPolicyURL = (langCode = 'en-GB') => {
  const baseUrl = `https://www.insights.com/`;
  switch (langCode) {
    case 'da-DK':
      return `${baseUrl}dk/danish-insights-online-privacy-notice/`;
    case 'nl-NL':
      return `${baseUrl}dutch-experience-portal-privacy-notice/`;
    case 'fr-FR':
      return `${baseUrl}fr/avis-de-confidentialite-d-insights-online/`;
    case 'de-DE':
      return `${baseUrl}de/insights-online-datenschutzerklarung/`;
    case 'es-ES':
      return `${baseUrl}es/spanish-insights-online-privacy-notice/`;
    default:
      return `${baseUrl}general-privacy-notice/`;
  }
};
export default privacyPolicyURL;
