import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Box, FormHelperText, Typography, styled } from '@mui/material';
import { TextInputV2 } from '@insights-ltd/design-library';

type SubtextTextFieldProps = {
  id: string;
  label: string;
  name: string;
  subtext?: string;
  optional?: boolean;
  autoComplete?: string;
  register: any;
  errorMessage?: string;
  dataTestId: string;
  placeholder?: string;
};

const styles = {
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowWrapper: {
    marginBottom: '1.5rem',
  },
} as const;

const StyledError = styled(FormHelperText)({
  marginLeft: '0px',
  marginTop: '0px !important',
});

const SubtextTextField = ({
  id,
  label,
  name,
  subtext,
  optional,
  register,
  errorMessage,
  dataTestId,
  autoComplete,
  placeholder,
}: SubtextTextFieldProps) => {
  const {
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();

  const { ref, ...rest } = register(name, { required: !optional });
  const labelElement = (
    <Box>
      {label}
      &nbsp;
      {!optional ? (
        <Box
          component="span"
          sx={(theme) => ({
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.75rem',
            color: theme.palette.error.main,
          })}
          aria-hidden="true"
        >
          {t('ui.io.about_you.required_field')}
        </Box>
      ) : (
        <Box
          component="span"
          sx={(theme) => ({
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.75rem',
            color: theme.palette.grey[600],
          })}
        >
          {t('ui.io.about_you.optional_field')}
        </Box>
      )}
    </Box>
  );

  return (
    <div style={styles.rowWrapper}>
      <div style={styles.fieldWrapper}>
        <Box width={1}>
          <Box mb={1}>
            <TextInputV2
              id={id}
              fullWidth
              labelText={labelElement}
              placeholder={placeholder}
              variant="outlined"
              error={Boolean(errors[name])}
              autoComplete={autoComplete}
              inputProps={{
                'data-testid': dataTestId,
                'aria-required': !optional,
                'aria-invalid': errorMessage ? 'true' : 'false',
                'aria-describedby': errors[name] ? `${id}-error` : '',
              }}
              innerRef={ref}
              {...rest}
              sx={{ '& .MuiOutlinedInput-input': { width: '100%' } }}
            />
          </Box>
          {errors[name] ? (
            <StyledError id={`${id}-error`} error variant="outlined">
              {errorMessage}
            </StyledError>
          ) : (
            subtext && (
              <Box>
                <Typography color="textSecondary">{subtext}</Typography>
              </Box>
            )
          )}
        </Box>
      </div>
    </div>
  );
};
export default SubtextTextField;
