import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, styled } from '@mui/material';
import { H1, P } from '@insights-ltd/design-library';
import AlertIcon from 'components/Svgs/icons/AlertIcon';

const StyledDiv = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  alignItems: 'center',
  justifyContent: 'center',
  minWidth: '100%',
  minHeight: '100%',
});

const FullScreenError = ({
  heading,
  message,
}: {
  heading?: string;
  message: string;
}) => {
  const { t } = useTranslation();

  return (
    <StyledDiv>
      <Box mb={6}>
        <AlertIcon title="alert" />
      </Box>
      <H1>{heading || t('ui.io.error')}</H1>
      <Box mt={3} maxWidth={560} textAlign="center">
        <P>{message}</P>
      </Box>
    </StyledDiv>
  );
};

export default FullScreenError;
