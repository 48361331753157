import React from 'react';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { Evaluator as EvaluatorType } from 'types/evaluator';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Stack,
} from '@mui/material';
import { ColourChartSplitLayout, PrivacyPolicyCheckboxLabel } from 'components';
import { ButtonV2, CheckboxV2, P, Span } from '@insights-ltd/design-library';
import { PAGE_TITLE_KEYS, EVALUATOR_TITLE_KEYS } from 'variables';

type FormData = {
  privacyPolicy: boolean;
};

const PrivacyPolicy = ({
  privacyPolicyAccepted = false,
  onComplete,
}: {
  privacyPolicyAccepted?: boolean;
  onComplete: () => void;
}) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      privacyPolicy: privacyPolicyAccepted,
    },
    mode: 'onChange',
  });

  const error = Boolean(errors.privacyPolicy);

  return (
    <form onSubmit={handleSubmit(onComplete)} noValidate>
      <FormControl required error={error}>
        <FormControlLabel
          control={
            <Controller
              render={({
                field: { onChange, onBlur, value, name },
                formState: { errors: formStateErrors },
              }) => (
                <CheckboxV2
                  onBlur={onBlur}
                  onChange={(newValue: any) => onChange(newValue)}
                  checked={value}
                  name={name}
                  required
                  color="primary"
                  data-testid="privacy-policy-checkbox"
                  inputProps={{
                    'aria-describedby': `privacy-policy-error-label`,
                    'aria-required': 'true',
                    'aria-invalid': !!formStateErrors[name],
                    role: 'checkbox',
                  }}
                  sx={{ width: '42px' }}
                />
              )}
              name="privacyPolicy"
              control={control}
              rules={{ required: true }}
            />
          }
          label={<PrivacyPolicyCheckboxLabel />}
        />
        {error && (
          <FormHelperText id="privacy-policy-error-label">
            {t('ui.io.privacy_policy.required_error')}
          </FormHelperText>
        )}
      </FormControl>
      <Stack direction="row" justifyContent="flex-end" my={3}>
        <ButtonV2
          disableRipple
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          buttonText={t('ui.io.onboarding.get_started')}
        />
      </Stack>
    </form>
  );
};

type Props = {
  privacyPolicyAccepted?: boolean;
  onComplete: () => void;
  noSignUp?: boolean;
  subjectFullName?: string;
  model: EvaluatorType;
};

const ExploreWelcomeContent = ({
  onComplete,
  privacyPolicyAccepted,
}: Pick<Props, 'privacyPolicyAccepted' | 'onComplete'>) => {
  const { t } = useTranslation();

  return (
    <>
      <P color="textSecondary">
        {t('ui.io.onboarding.non_sign_up.welcome.explore.overview')}
      </P>
      <PrivacyPolicy
        onComplete={onComplete}
        privacyPolicyAccepted={privacyPolicyAccepted}
      />
      <P color="textSecondary" variant="body-bold">
        {t('ui.io.onboarding.non_sign_up.who_are_insights_heading')}
      </P>
      <P color="textSecondary">
        {t('ui.io.onboarding.non_sign_up.who_are_insights_body')}
      </P>
      <P color="textSecondary" variant="body-bold">
        {t('ui.io.onboarding.non_sign_up.what_is_insights_heading')}
      </P>
      <P color="textSecondary">
        {t('ui.io.onboarding.non_sign_up.what_is_insights_body')}
      </P>
    </>
  );
};

const DFCWelcomeContent = ({
  subjectFullName,
  onComplete,
  privacyPolicyAccepted,
}: Pick<Props, 'subjectFullName' | 'privacyPolicyAccepted' | 'onComplete'>) => {
  const { t } = useTranslation();
  return (
    <Stack spacing="1rem">
      <P color="textSecondary">
        <Trans
          values={{ subjectFullName }}
          components={{
            strong: <Span variant="body-bold" />,
          }}
          i18nKey="ui.io.onboarding.welcome.bold.subjectfullname.overview.dfc"
        />
      </P>
      <P color="textSecondary">
        {t('ui.io.onboarding.welcome.overview.intro.dfc', {
          subjectFullName,
        })}
      </P>
      <P color="textSecondary">
        {t('ui.io.onboarding.welcome.overview.intro.body.dfc', {
          subjectFullName,
        })}
      </P>
      <P color="textSecondary">
        {t('ui.io.onboarding.welcome.overview.intro.body.continued.dfc', {
          subjectFullName,
        })}
      </P>
      <PrivacyPolicy
        onComplete={onComplete}
        privacyPolicyAccepted={privacyPolicyAccepted}
      />
    </Stack>
  );
};

const WelcomeContent = ({
  privacyPolicyAccepted,
  onComplete,
  noSignUp = false,
  subjectFullName,
  model,
}: Props) => {
  const { t } = useTranslation();

  if (model === 'EXPLORE') {
    return (
      <ExploreWelcomeContent
        onComplete={onComplete}
        privacyPolicyAccepted={privacyPolicyAccepted}
      />
    );
  }

  if (model === 'DFC') {
    return (
      <DFCWelcomeContent
        subjectFullName={subjectFullName}
        onComplete={onComplete}
        privacyPolicyAccepted={privacyPolicyAccepted}
      />
    );
  }

  return (
    <Stack spacing={2}>
      <P color="textSecondary">
        {t(
          noSignUp
            ? 'ui.io.onboarding.non_sign_up.welcome.overview'
            : 'ui.io.onboarding.welcome.overview',
        )}
      </P>
      <PrivacyPolicy
        onComplete={onComplete}
        privacyPolicyAccepted={privacyPolicyAccepted}
      />
    </Stack>
  );
};

const Welcome = ({
  privacyPolicyAccepted,
  onComplete,
  noSignUp = false,
  subjectFullName,
  model,
}: Props) => {
  const { t } = useTranslation();

  return (
    <ColourChartSplitLayout
      title={t('ui.io.onboarding.welcome_title')}
      model={model}
      keepBanner
    >
      <Helmet
        title={`${t(PAGE_TITLE_KEYS.WELCOME)} - ${t(
          EVALUATOR_TITLE_KEYS[model],
        )}`}
      />
      <WelcomeContent
        noSignUp={noSignUp}
        privacyPolicyAccepted={privacyPolicyAccepted}
        onComplete={onComplete}
        subjectFullName={subjectFullName}
        model={model}
      />
    </ColourChartSplitLayout>
  );
};

export default Welcome;
