import { P, WistiaVideoPlayerButton } from '@insights-ltd/design-library';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { Box } from '@mui/material';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getCaptionLanguageCode } from 'variables';

const SectionIntro = () => {
  const { t, i18n } = useTranslation();
  const introVideoId = 'g1428ack42';
  const [captionLanguage, setCaptionsLanguage] = useState(
    getCaptionLanguageCode(i18n.language, 'PREFERRED_DIALECTS'),
  );

  useMemo(() => {
    if (i18n.language !== captionLanguage) {
      setCaptionsLanguage(
        getCaptionLanguageCode(i18n.language, 'PREFERRED_DIALECTS'),
      );
    }
  }, [i18n.language, captionLanguage]);

  return (
    <Box
      sx={(theme) => ({
        margin: `${theme.spacing(spacingSizeMap.M)} 0`,
        maxWidth: '720px',
        [theme.breakpoints.up('md')]: {
          margin: `${theme.spacing(spacingSizeMap.L)} 0`,
        },
      })}
    >
      <P color="textSecondary">
        {t('ui.io.explore.evaluator.digital_profile.intro')}
      </P>
      <Box
        sx={{
          marginTop: '1rem',
          '& .MuiButtonBase-root': {
            padding: '10px 20px',
            width: 'max-content',
          },
        }}
      >
        <WistiaVideoPlayerButton
          key={i18n.language}
          videoId={introVideoId}
          wrapperId="wistia-player-container-1"
          buttonText={t(
            `ui.io.explore.evaluator.digital_profile.intro.play_video`,
          )}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          captionsLanguage={captionLanguage}
          setCaptionsToChosenLanguage
        />
      </Box>
    </Box>
  );
};

export default SectionIntro;
