import { ok, notFound } from '@insights-ltd/design-library/src/utils/mocking';
import { setupWorker } from 'msw/browser';
import { evaluatorLinks, evaluators, tokens } from './handlers';
import { tokenDetailsDFC } from './mock-data/authentication';
import { dfcEvaluator } from './mock-data/evaluator';

const handlers = [
  evaluatorLinks.verifyLink('HOMER')(ok()),
  evaluatorLinks.verifyLink('BART')(notFound()),
  tokens.get('dfc-mock-data-token')(ok(tokenDetailsDFC)),
  evaluators.get('DFC')(ok(dfcEvaluator)),
];

export const worker = setupWorker(...handlers);
