import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { MenuItem } from '@mui/material';
import { JOB_LEVELS, INDUSTRY_SECTORS, LENGTHS_OF_SERVICE } from 'variables';
import { Evaluator } from 'types/evaluator';
import { setFocusOnFirstError } from 'utils/formHelpers';
import { Select } from './FormInputs';
import FormLayout from './FormLayout';
import FormNavigation from './FormNavigation';
import { IndustrySectors, Step4Data } from './types';
import { StyledFormInputWrapper } from './StyledComponents';

type Sectors = keyof typeof INDUSTRY_SECTORS;

const Step4 = ({
  onNext,
  onBack,
  stepNumber,
  stepMax,
  userInfo,
  model,
}: {
  onNext: SubmitHandler<FieldValues>;
  onBack?: () => void;
  stepNumber: number;
  stepMax: number;
  userInfo: Step4Data;
  model: Evaluator;
}) => {
  const { t } = useTranslation();

  const [validSectors, setValidSectors] = useState<IndustrySectors[]>([]);
  const [validSubSectors, setValidSubSectors] = useState<IndustrySectors[]>([]);

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { errors },
    setFocus,
  } = useForm<FieldValues>({
    defaultValues: userInfo,
  });

  const currentSectorSelection = watch('sector') as Sectors | '';
  const currentSubSectorSelection = watch('subSector') as string;

  useEffect(() => {
    const buildList = (list: string[]) => {
      return list
        .map((item) => {
          return { sectorKey: item, sectorName: t(item) };
        })
        .sort((a, b) =>
          new Intl.Collator().compare(a.sectorName, b.sectorName),
        );
    };

    if (currentSectorSelection !== '') {
      const industrySubSectors = buildList(
        INDUSTRY_SECTORS[currentSectorSelection].subSectors,
      );
      const isSubSectorPresent = industrySubSectors.find((element) => {
        return element.sectorKey === currentSubSectorSelection;
      });
      if (!isSubSectorPresent) {
        setValue('subSector', '');
      }
      setValidSubSectors(industrySubSectors);
    }
    const industrySectorsList = buildList(Object.keys(INDUSTRY_SECTORS));
    const movingItemIndex = industrySectorsList.findIndex(
      ({ sectorKey }) =>
        sectorKey === 'ui.io.demographics.job.sector.rather_not_say',
    );
    const movingItem = industrySectorsList.splice(movingItemIndex, 1);
    const industrySectors = [...movingItem, ...industrySectorsList];
    setValidSectors(industrySectors);
    // adding t function to dependency array causes tests to hang due to issues with vite, need to revisit.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSectorSelection, currentSubSectorSelection, setValue]);

  return (
    <FormLayout
      step={stepNumber}
      stepMax={stepMax}
      subtitle={t('ui.io.about_you.step_three.subtitle')}
      model={model}
    >
      <StyledFormInputWrapper
        onSubmit={handleSubmit(onNext, (submitErrors) => {
          setFocusOnFirstError(submitErrors, setFocus);
        })}
        noValidate
      >
        <Select
          id="length-of-service"
          name="lengthOfService"
          label={t(
            'ui.io.demographics.job.length_of_service.input_description',
          )}
          control={control}
          error={Boolean(errors.lengthOfService)}
          errorMessage={t('ui.io.about_you.error.length_of_service_required')}
          autoComplete="length-of-service"
        >
          <MenuItem disabled hidden value="" style={{ display: 'none' }}>
            {t('ui.io.about_you.please_select.placeholder')}
          </MenuItem>
          {LENGTHS_OF_SERVICE.map((textKey) => (
            <MenuItem disableRipple key={textKey} value={textKey}>
              {t(textKey)}
            </MenuItem>
          ))}
        </Select>
        <Select
          id="job-level"
          name="jobLevel"
          label={t(
            'ui.io.demographics.job.level_of_position.input_description',
          )}
          control={control}
          error={Boolean(errors.jobLevel)}
          errorMessage={t('ui.io.about_you.error.job_level_required')}
          autoComplete="job-level"
        >
          <MenuItem disabled hidden value="" style={{ display: 'none' }}>
            {t('ui.io.about_you.please_select.placeholder')}
          </MenuItem>
          {JOB_LEVELS.map((textKey) => (
            <MenuItem disableRipple key={textKey} value={textKey}>
              {t(textKey)}
            </MenuItem>
          ))}
        </Select>
        <Select
          id="sector"
          name="sector"
          label={t('ui.io.demographics.job.sector.input_description')}
          control={control}
          error={Boolean(errors.sector)}
          errorMessage={t('ui.io.about_you.error.sector_required')}
          data-testid="sectors-select"
          autoComplete="job-sector"
        >
          <MenuItem disabled hidden value="" style={{ display: 'none' }}>
            {t('ui.io.about_you.please_select.placeholder')}
          </MenuItem>
          {validSectors.map((item) => (
            <MenuItem disableRipple key={item.sectorKey} value={item.sectorKey}>
              {item.sectorName}
            </MenuItem>
          ))}
        </Select>
        <Select
          id="sub-sector"
          name="subSector"
          label={t('ui.io.demographics.job.subsector.input_description')}
          control={control}
          error={Boolean(errors.subSector)}
          errorMessage={t('ui.io.about_you.error.subsector_required')}
          autoComplete="job-sub-sector"
        >
          <MenuItem disabled hidden value="" style={{ display: 'none' }}>
            {t('ui.io.about_you.please_select.placeholder')}
          </MenuItem>
          {validSubSectors &&
            validSubSectors.map((industrySubSector) => (
              <MenuItem
                disableRipple
                key={industrySubSector.sectorKey}
                value={industrySubSector.sectorKey}
              >
                {industrySubSector.sectorName}
              </MenuItem>
            ))}
        </Select>
        <FormNavigation onBack={onBack} />
      </StyledFormInputWrapper>
    </FormLayout>
  );
};

export default Step4;
