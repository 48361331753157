import { createBetaFlags } from '@insights-ltd/design-library';
import { useTranslation } from 'react-i18next';

export const BETA_DEFAULT = false;

const PRODUCTION_HOSTNAME = 'experience.insights.com';

export const getBetaAvailable = () => {
  return window.location.hostname !== PRODUCTION_HOSTNAME;
};

/*
When adding a new beta feature, add its name here.
When releasing a beta feature to production, remove it from here and TypeScript
will fail the build until every usage of it is removed.
 */
export type FEATURE = 'DFC';

export const { useBetaEnabled, BetaFeature } = createBetaFlags<FEATURE>();

export const useBetaTranslation = (feature: FEATURE) => {
  const isBetaEnabled = useBetaEnabled(feature);
  const { t } = useTranslation();
  return (key: any, options: any) => {
    const suffix = isBetaEnabled ? '.beta' : '';
    return t(key + suffix, options);
  };
};
