import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
} from '@mui/material';
import { Text } from '@insights-ltd/design-library';
import { useTranslation } from 'react-i18next';
import QuestionNavigation from './QuestionNavigation';

type FormDataType = { choice: string };
type Props = {
  prompt: string;
  subjectName: string;
  choices: { key: string; text: string }[];
  submitAnswer: (value: string) => void;
  answer?: string;
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  onBack: () => void;
};

const Choice = ({
  prompt,
  subjectName,
  choices,
  submitAnswer,
  answer,
  isFirstQuestion,
  isLastQuestion,
  onBack,
}: Props) => {
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FormDataType>({
    defaultValues: { choice: answer || '' },
  });
  const { t } = useTranslation();

  const onSubmit = (data: FormDataType) => submitAnswer(data.choice);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container my={1}>
        <Grid item>
          <Text variant="h3">{prompt}</Text>
        </Grid>
        <Grid item>
          <Text variant="body">
            {t('ui.io.evaluator.dfc.choice_question.subheading', {
              subjectName,
            })}
          </Text>
        </Grid>
      </Grid>
      {errors.choice?.type === 'required' && (
        <Text variant="body-bold" color="error" as="p">
          {t('ui.io.evaluator.dfc.choice-question.error.required')}
        </Text>
      )}
      <Controller
        name="choice"
        control={control}
        rules={{ required: true }}
        render={({ field: { ref, ...rest } }) => (
          <RadioGroup role="radiogroup" aria-label={prompt} {...rest}>
            {choices.map(({ key, text }) => (
              <FormControlLabel
                key={key}
                value={key}
                control={
                  <Radio
                    inputProps={{ role: 'radio' }}
                    color="primary"
                    disableRipple
                  />
                }
                label={text}
              />
            ))}
          </RadioGroup>
        )}
      />
      <Divider />
      <QuestionNavigation
        isFirstQuestion={isFirstQuestion}
        isLastQuestion={isLastQuestion}
        onBack={onBack}
      />
    </form>
  );
};
export default Choice;
