import { Box, Container, styled } from '@mui/material';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import { Text } from '@insights-ltd/design-library';
import React from 'react';
import Logo from 'components/Svgs/InsightsLogo';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';

type ExploreHeaderProps = {
  title?: string;
};

const StyledAppHeaderWrapper = styled('header')({
  display: 'flex',
  minHeight: '72px',
  boxShadow: '0 2px 16px -16px rgba(0, 0, 0, 0.5)',
});

const ExploreHeader = ({ title }: ExploreHeaderProps) => {
  return (
    <StyledAppHeaderWrapper>
      <Container
        maxWidth="lg"
        sx={(theme) => ({
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: `${theme.spacing(spacingSizeMap.S)} !important`,
          paddingRight: `${theme.spacing(spacingSizeMap.S)} !important`,
          [theme.breakpoints.up('lg')]: {
            paddingLeft: '0 !important',
            paddingRight: '0 !important',
          },
        })}
        translate="no"
      >
        <Box
          sx={(theme) => ({
            display: 'flex',
            '& svg': {
              fill: theme.palette.blue.main,
              width: '130px',
              height: '34px',
            },
          })}
        >
          <Logo data-testid="logo-svg" title="Insights logo" />
        </Box>
        <Text variant="body-bold">{title}</Text>
        <Box
          sx={{ display: 'flex', justifyContent: 'flex-end', width: '215px' }}
        >
          <LanguageSelector />
        </Box>
      </Container>
    </StyledAppHeaderWrapper>
  );
};

export default ExploreHeader;
