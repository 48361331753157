import { useMutation, useQuery } from '@tanstack/react-query';
import * as api from './profiles';

export const useGetProfileScores = (profileId: string, apiToken: string) =>
  useQuery(['profileScores', profileId], async () => {
    return api.getProfileScores(profileId, apiToken);
  });

export const useGetMagicLinkEmail = (apiToken: string) =>
  useQuery(['apiToken', apiToken], async () => {
    return api.getMagicLinkEmail(apiToken);
  });

export const useReissueMagicLink = () =>
  useMutation(({ apiToken, locale }: { apiToken: string; locale: string }) => {
    return api.reissueMagicLink(apiToken, locale);
  });
