import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, styled } from '@mui/material';

const ButtonContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '1rem',
});

type QuestionNavigationProps = {
  isFirstQuestion: boolean;
  isLastQuestion: boolean;
  onBack: () => void;
};

const QuestionNavigation = ({
  isFirstQuestion,
  isLastQuestion,
  onBack,
}: QuestionNavigationProps) => {
  const { t } = useTranslation();

  return (
    <ButtonContainer>
      <Button
        sx={{ visibility: isFirstQuestion ? 'hidden' : 'visible' }}
        disabled={isFirstQuestion}
        onClick={onBack}
        variant="outlined"
        size="large"
        aria-hidden={isFirstQuestion}
        disableRipple
      >
        {t('ui.io.navigation.back')}
      </Button>
      <Button
        disableRipple
        type="submit"
        variant="contained"
        color="primary"
        size="large"
      >
        {isLastQuestion
          ? t('ui.io.evaluator.finish')
          : t('ui.io.navigation.next')}
      </Button>
    </ButtonContainer>
  );
};
export default QuestionNavigation;
