import React, { useState, useEffect } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { ButtonV2 } from '@insights-ltd/design-library/src/components/ButtonV2';
import { ColourEnergyBox, H4, Span } from '@insights-ltd/design-library';
import { Trans, useTranslation } from 'react-i18next';
import { Colour, ColourEnergy, ColourEnergyProps } from 'types/constants';
import { colourMap } from 'variables';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import SvgHelpWheel from '@insights-ltd/design-library/src/components/Svgs/HelpWheelV2';

const StyledFloatingButton = styled(ButtonV2)(({ theme }) => ({
  border: 'none',
  borderRadius: '24px',
  backgroundColor: theme.palette.grey[100],
  cursor: 'pointer',
  bottom: 0,
  right: 0,
  '&.MuiButton-root': {
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  '&.MuiButton-outlined': {
    '& svg': {
      height: '20px',
      width: '20px',
      stroke: '#fff !important',
    },
  },
}));

const FloatingButton = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on component mount
    // Add event listener to handle scrolling when the overlay is open
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup the event listener when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const colourEnergies = (
    Object.keys(colourMap) as ColourEnergy[]
  ).reduce<ColourEnergyProps>((prev, colour) => {
    const colourEnergy: Colour = colourMap[colour];

    const colourEnergyName = t(
      `ui.io.explore.evaluator.digital_profile.colour_energy_${colourEnergy}_name`,
    );

    const colourDescription = t(
      `ui.io.explore.evaluator.digital_profile.colour_energy_${colourEnergy}_description`,
    );

    return {
      ...prev,
      [colour]: { colourEnergyName, colourDescription },
    };
  }, {} as ColourEnergyProps);

  return (
    <Box
      sx={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}
    >
      {!isOpen && (
        <StyledFloatingButton
          data-testid="floating-help"
          variant="outlined"
          buttonText={t(
            'ui.io.explore.evaluator.digital_profile.floating_button.help',
          )}
          buttonIcon={<SvgHelpWheel />}
          onClick={handleButtonClick}
        />
      )}
      {isOpen && (
        <Box
          sx={(theme) => ({
            position: 'fixed',
            bottom: 0,
            right: 0,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(spacingSizeMap.S),
            height: 'calc(100% - 72px)',
            backgroundColor: theme.palette.grey[100],
            zIndex: 1001,
            overflow: 'scroll',
            boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.05) inset',
          })}
        >
          <Box sx={{ paddingBottom: '0.5rem', maxWidth: '720px' }}>
            <H4 style={{ marginBottom: '0.5rem', fontSize: '1.125rem' }}>
              {t(
                'ui.io.explore.evaluator.digital_profile.colour_energies_header',
              )}
            </H4>
            <Typography color="textSecondary">
              <Trans
                i18nKey="ui.io.explore.evaluator.digital_profile.colour_energies_header_content"
                components={{
                  bold: <Span variant="body-bold" color="textSecondary" />,
                }}
              />
            </Typography>
          </Box>
          <ColourEnergyBox colourStrings={colourEnergies} />
          <StyledFloatingButton
            sx={{ bottom: 20, right: 20, position: 'fixed' }}
            data-testid="floating-close"
            variant="outlined"
            buttonIcon={<SvgHelpWheel />}
            onClick={handleButtonClick}
            buttonText={t(
              'ui.io.explore.evaluator.digital_profile.floating_button.close',
            )}
          />
        </Box>
      )}
    </Box>
  );
};

export default FloatingButton;
