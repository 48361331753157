import {
  get,
  head,
  post,
} from '@insights-ltd/design-library/src/utils/mocking';
import { Evaluator } from 'types/evaluator';
import { GetTokenDetailsResponse } from 'api/authentication';
import {
  EvaluatorResponse,
  EvaluatorsResponse,
  Event,
  Languages,
  RequestErrorBody,
} from 'api/evaluator';
import { ProfileScoreResponse } from 'api/profiles';
import { LanguageSelectOptions } from 'types/dialects';
import { http, HttpResponse } from 'msw';
import { MOCK_PROFILE_SCORE_RESPONSE } from 'test/utils';

const API_ROOT = `${import.meta.env.VITE_API_PREFIX}/api/v1`;

export const evaluators = {
  get: <Model extends Evaluator>(model: Model) =>
    get<EvaluatorResponse<Model> | undefined>(
      `${API_ROOT}/evaluators/${model}`,
    ),
  all: (eventId: string) =>
    get<EvaluatorsResponse | undefined>(
      `${API_ROOT}/evaluator-requests/${eventId}`,
    ),
  submitWithToken: (model: Evaluator, token: string) =>
    post<undefined>(`${API_ROOT}/evaluators/${model}/responses/${token}`),
  submitWithoutToken: (model: Evaluator) =>
    post<undefined>(`${API_ROOT}/evaluators/${model}/responses`),
};

export const dialects = {
  get: (languageSelectOption: LanguageSelectOptions) =>
    get<Languages>(`${API_ROOT}/dialects/${languageSelectOption}`),
};

export const tokens = {
  get: (token: string) =>
    get<GetTokenDetailsResponse | undefined>(`${API_ROOT}/invite/${token}`),
  validate: (token: string) => get<undefined>(`${API_ROOT}/invites/${token}`),
};

export const evaluatorLinks = {
  verifyLink: (evaluatorLinkId: string) =>
    head<undefined>(`${API_ROOT}/evaluator-links/${evaluatorLinkId}`),
  verifyEmail: (evaluatorLinkId: string) =>
    post<RequestErrorBody | undefined>(
      `${API_ROOT}/evaluator-links/${evaluatorLinkId}/signup/verify`,
    ),
};

export const events = {
  all: get<{ events: Event[] } | undefined>(`${API_ROOT}/learners/me/events`),
};

export const exploreProfileScores = {
  get: (profileId: string, apiToken: string) =>
    get<ProfileScoreResponse | RequestErrorBody | undefined>(
      `${API_ROOT}/explore-profiles/${profileId}?token=${apiToken}`,
    ),
};

export const exploreThemesProfileScores = {
  get: (profileId: string, apiToken: string) =>
    http.get(
      `${API_ROOT}/explore-profiles/:profileId`,
      ({ request, params }) => {
        const urlProfileId = params.profileId;
        const url = new URL(request.url);
        const token = url.searchParams.get('token');

        if (urlProfileId === profileId && token === apiToken) {
          return HttpResponse.json(MOCK_PROFILE_SCORE_RESPONSE, {
            status: 200,
          });
        }
        return HttpResponse.json(
          { error: 'Forbidden' },
          {
            status: 403,
          },
        );
      },
    ),
};

export const exploreMagicLinkEmail = {
  get: (apiToken: string) =>
    get<{ emailAddress: string }>(
      `${API_ROOT}/profile-link-reissue/email/${apiToken}`,
    ),
  reIssue: () =>
    post<RequestErrorBody | undefined>(
      `${API_ROOT}/profile-link-reissue/renew`,
    ),
};
