import React, { useState } from 'react';
import SvgAlertDiamond from '@insights-ltd/design-library/src/components/Svgs/streamline-bold/AlertDiamondV2';
import SvgEmailAction from '@insights-ltd/design-library/src/components/Svgs/streamline-bold/EmailActionV2';
import { H2, P } from '@insights-ltd/design-library';
import { ButtonV2 } from '@insights-ltd/design-library/src/components/ButtonV2';
import { TextInputV2 } from '@insights-ltd/design-library/src/components/TextInputV2';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import Icon from '@insights-ltd/design-library/src/components/Icon/Icon';
import { Box, styled } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useGetMagicLinkEmail, useReissueMagicLink } from 'api/profile.hooks';
import { FullScreenError, FullScreenSpinner } from 'components';

const StyledH2 = styled(H2)(({ theme }) => ({
  margin: `${theme.spacing(spacingSizeMap.S)} 0`,
}));

type LinkReissueForm = {
  emailAddress: string;
};

type ExploreReissueProps = {
  apiToken: string | undefined;
  locale: any;
};

const ExploreReissue = ({ apiToken, locale }: ExploreReissueProps) => {
  const { t } = useTranslation();
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<LinkReissueForm>();
  const [emailSent, setEmailSent] = useState(false);
  const { status, data } = useGetMagicLinkEmail(apiToken!);
  const { mutate } = useReissueMagicLink();

  const onSubmitHandler = () => {
    mutate(
      { apiToken: apiToken!, locale },
      {
        onSuccess: () => {
          setEmailSent(true);
        },
      },
    );
  };

  if (status === 'loading') {
    return <FullScreenSpinner />;
  }
  if (status === 'error') {
    return (
      <FullScreenError
        message={t('ui.io.explore.evaluator.digital_profile.error')}
      />
    );
  }

  return (
    <Box sx={{ width: '66.6%' }}>
      {!emailSent ? (
        <>
          <Icon icon={<SvgAlertDiamond />} iconSize="large" />
          <StyledH2>{t('ui.io.explore.send-link.title')}</StyledH2>
          <P>{t('ui.io.explore.send-link.description')}</P>
          <Box
            component="form"
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              gap: theme.spacing(spacingSizeMap.S),
              marginTop: theme.spacing(spacingSizeMap.M),
              '> *:first-child': {
                flexGrow: '1',
              },
            })}
            onSubmit={handleSubmit(onSubmitHandler)}
          >
            <Box>
              <TextInputV2
                id="emailAddress"
                labelText={t('ui.io.explore.send-link.label')}
                placeholder={t('ui.io.explore.send-link.placeholder')}
                fullWidth
                error={Boolean(errors.emailAddress)}
                helperText={
                  errors.emailAddress &&
                  t('ui.io.explore.send-link.error.required')
                }
                {...register('emailAddress')}
                value={data.emailAddress}
                disabled
              />
            </Box>
            <ButtonV2
              sx={{
                marginTop: '32px',
              }}
              buttonText={t('ui.io.explore.send-link.button')}
              type="submit"
            />
          </Box>
        </>
      ) : (
        <>
          <Icon icon={<SvgEmailAction />} iconColour="blue" iconSize="large" />
          <StyledH2>{t('ui.io.explore.link-sent.title')}</StyledH2>
          <P>
            {t('ui.io.explore.link-sent.description', {
              emailAddress: data.emailAddress,
            })}
          </P>
        </>
      )}
    </Box>
  );
};

export default ExploreReissue;
