import { BaseDialects } from '@insights-ltd/design-library';
import {
  LocalDetails,
  Locales,
  ProfileDetails,
  Pronoun,
  Pronouns,
  PreferredDialects,
} from 'types/dialects';
import { Evaluator } from 'types/evaluator';

export type Dialects = BaseDialects | 'is-IS' | 'es-CU';

export type TypesOfDialects =
  | { [key: string]: Dialects }
  | { [key: string]: PreferredDialects };

export const DIALECTS: { [key: string]: Dialects } = {
  AFRIKAANS: 'af-ZA',
  BAHASA: 'id-ID',
  BULGARIAN: 'bg-BG',
  CHINESE_SIMPLIFIED: 'zh-CN',
  CHINESE_TRADITIONAL_HK: 'zh-HK',
  CROATIAN: 'hr-HR',
  CZECH: 'cs-CZ',
  DANISH: 'da-DK',
  DUTCH: 'nl-NL',
  ENGLISH: 'en-GB',
  ENGLISH_SOUTH_AFRICA: 'en-ZA',
  ESTONIAN: 'et-EE',
  FINNISH: 'fi-FI',
  FRENCH: 'fr-FR',
  FRENCH_CANADA: 'fr-CA',
  GEORGIAN: 'ka-GE',
  GERMAN: 'de-DE',
  GREEK: 'el-GR',
  HUNGARIAN: 'hu-HU',
  ICELANDIC: 'is-IS',
  ITALIAN: 'it-IT',
  JAPANESE: 'ja-JP',
  KOREAN: 'ko-KR',
  LITHUANIAN: 'lt-LT',
  NORWEGIAN_BOKMAL: 'nb-NO',
  POLISH: 'pl-PL',
  PORTUGUESE_BRAZIL: 'pt-BR',
  PORTUGUESE_PORTUGAL: 'pt-PT',
  ROMANIAN: 'ro-RO',
  RUSSIAN: 'ru-RU',
  SERBIAN_LATIN: 'sr-RS',
  SLOVAKIAN: 'sk-SK',
  SLOVENIAN: 'sl-SI',
  SPANISH_MEXICO: 'es-MX',
  SPANISH_SPAIN: 'es-ES',
  SWEDISH: 'sv-SE',
  TURKISH: 'tr-TR',
  UKRAINIAN: 'uk-UA',
  VIETNAMESE: 'vi-VN',
};

export const PREFERRED_DIALECTS: { [key: string]: PreferredDialects } = {
  ENGLISH: 'en-GB',
  DANISH: 'da-DK',
  DUTCH: 'nl-NL',
  FRENCH: 'fr-FR',
  SPANISH_SPAIN: 'es-ES',
  GERMAN: 'de-DE',
};

const typesOfDialects: { [key: string]: TypesOfDialects } = {
  BASE_DIALECTS: DIALECTS,
  PREFERRED_DIALECTS,
};

export const PRONOUN_SHE: Pronoun = 'SHE';
export const PRONOUN_HE: Pronoun = 'HE';
export const PRONOUN_THEY: Pronoun = 'THEY';

export const PRONOUNS_EXCLUSIVE: Pronouns[] = [
  { textKey: 'ui.io.pronouns.he/him/his', value: PRONOUN_HE },
  { textKey: 'ui.io.pronouns.she/her/hers', value: PRONOUN_SHE },
];

export const PRONOUNS_INCLUSIVE: Pronouns[] = [
  { textKey: 'ui.io.pronouns.he/him/his', value: PRONOUN_HE },
  { textKey: 'ui.io.pronouns.she/her/hers', value: PRONOUN_SHE },
  { textKey: 'ui.io.pronouns.they/them/theirs', value: PRONOUN_THEY },
];

export const LOCALES: Locales = {
  all: [
    {
      tag: DIALECTS.AFRIKAANS,
      text: 'Afrikaans (ZA)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.BAHASA,
      text: 'Bahasa (ID)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.CZECH,
      text: 'Čeština (CZ)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.DANISH,
      text: 'Dansk (DK)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS', 'DFC', 'EXPLORE'],
    },
    {
      tag: DIALECTS.GERMAN,
      text: 'Deutsch (DE)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS', 'DFC', 'EXPLORE'],
    },
    {
      tag: DIALECTS.ESTONIAN,
      text: 'Eesti keel (EE)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.ENGLISH,
      text: 'English (GB)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS', 'DFC', 'EXPLORE'],
    },
    {
      tag: DIALECTS.ENGLISH_SOUTH_AFRICA,
      text: 'English (ZA)',
      enabled: true,
      evaluator: ['DISCOVERY'],
    },
    {
      tag: DIALECTS.SPANISH_SPAIN,
      text: 'Español (ES)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS', 'DFC', 'EXPLORE'],
    },
    {
      tag: DIALECTS.SPANISH_MEXICO,
      text: 'Español (MX)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.FRENCH,
      text: 'Français (FR)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS', 'DFC', 'EXPLORE'],
    },
    {
      tag: DIALECTS.FRENCH_CANADA,
      text: 'Français (CA)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.CROATIAN,
      text: 'Hravtski (HR)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.ICELANDIC,
      text: 'Íslenska (IS)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.ITALIAN,
      text: 'Italiano (IT)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.LITHUANIAN,
      text: 'Lietuvių k. (LT)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.HUNGARIAN,
      text: 'Magyar (HU)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.DUTCH,
      text: 'Nederlands (NL)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS', 'DFC', 'EXPLORE'],
    },
    {
      tag: DIALECTS.NORWEGIAN_BOKMAL,
      text: 'Norsk bokmål (NO)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.POLISH,
      text: 'Polski (PL)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.PORTUGUESE_BRAZIL,
      text: 'Português (BR)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.PORTUGUESE_PORTUGAL,
      text: 'Português (PT)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.ROMANIAN,
      text: 'Română (RO)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.SLOVAKIAN,
      text: 'Slovenčina (SK)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.SLOVENIAN,
      text: 'Slovenščina (SI)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.SERBIAN_LATIN,
      text: 'Srpski latinica (RS)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.FINNISH,
      text: 'Suomi (FI)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.SWEDISH,
      text: 'Svenska (SE)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.VIETNAMESE,
      text: 'Tiếng Việt (VN)',
      enabled: true,
      evaluator: ['DISCOVERY'],
    },
    {
      tag: DIALECTS.TURKISH,
      text: 'Türkçe (TR)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.GREEK,
      text: 'Ελληνικά (GR)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.BULGARIAN,
      text: 'Български (BG)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.RUSSIAN,
      text: 'Русский (RF)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.UKRAINIAN,
      text: 'Українська (UA)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.GEORGIAN,
      text: 'ქართული (GE)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.KOREAN,
      text: '한국어 (KR)',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.JAPANESE,
      text: '日本語（JP）',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.CHINESE_SIMPLIFIED,
      text: '中文（CN）',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
    {
      tag: DIALECTS.CHINESE_TRADITIONAL_HK,
      text: '中文（HK）',
      enabled: true,
      evaluator: ['DISCOVERY', 'DDDRS'],
    },
  ],
  default: DIALECTS.ENGLISH,
};

export const PROFILE_DIALECTS: { [key: string]: ProfileDetails } = {
  [DIALECTS.AFRIKAANS]: {
    textKey: 'ui.common.languages.afrikaans_south_africa',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: false,
  },
  [DIALECTS.BAHASA]: {
    textKey: 'ui.common.languages.bahasa_indonesia',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: false,
  },
  [DIALECTS.BULGARIAN]: {
    textKey: 'ui.common.languages.bulgarian_bulgaria',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.CHINESE_SIMPLIFIED]: {
    textKey: 'ui.common.languages.chinese_china',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.CHINESE_TRADITIONAL_HK]: {
    textKey: 'ui.common.languages.chinese_hong_kong',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.CROATIAN]: {
    textKey: 'ui.common.languages.croatian_croatia',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.CZECH]: {
    textKey: 'ui.common.languages.czech_czech_republic',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.DANISH]: {
    textKey: 'ui.common.languages.danish_denmark',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.DUTCH]: {
    textKey: 'ui.common.languages.dutch_netherlands',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.ENGLISH]: {
    textKey: 'ui.common.languages.english_united_kingdom',
    pronouns: PRONOUNS_INCLUSIVE,
    enabled: true,
  },
  [DIALECTS.ENGLISH_SOUTH_AFRICA]: {
    textKey: 'ui.common.languages.english_south_africa',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: false,
  },
  [DIALECTS.ESTONIAN]: {
    textKey: 'ui.common.languages.estonian_estonia',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: false,
  },
  [DIALECTS.FINNISH]: {
    textKey: 'ui.common.languages.finnish_finland',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.FRENCH]: {
    textKey: 'ui.common.languages.french_france',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.FRENCH_CANADA]: {
    textKey: 'ui.common.languages.french_canada',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.GEORGIAN]: {
    textKey: 'ui.common.languages.georgian_georgia',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: false,
  },
  [DIALECTS.GERMAN]: {
    textKey: 'ui.common.languages.german_germany',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.GREEK]: {
    textKey: 'ui.common.languages.greek_greece',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.HUNGARIAN]: {
    textKey: 'ui.common.languages.hungarian_hungary',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.ITALIAN]: {
    textKey: 'ui.common.languages.italian_italy',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.JAPANESE]: {
    textKey: 'ui.common.languages.japanese_japan',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.KOREAN]: {
    textKey: 'ui.common.languages.korean_korea',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: false,
  },
  [DIALECTS.LITHUANIAN]: {
    textKey: 'ui.common.languages.lithuanian_lithuania',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.NORWEGIAN_BOKMAL]: {
    textKey: 'ui.common.languages.norwegian_bokmal_norway',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.POLISH]: {
    textKey: 'ui.common.languages.polish_poland',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.PORTUGUESE_BRAZIL]: {
    textKey: 'ui.common.languages.portuguese_brazil',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.PORTUGUESE_PORTUGAL]: {
    textKey: 'ui.common.languages.portuguese_portugal',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.ROMANIAN]: {
    textKey: 'ui.common.languages.romanian_romania',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.RUSSIAN]: {
    textKey: 'ui.common.languages.russian_russian_federation',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.SERBIAN_LATIN]: {
    textKey: 'ui.common.languages.serbian_bosnia_herzegovina_latin',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.SLOVAKIAN]: {
    textKey: 'ui.common.languages.slovak_slovakia',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: false,
  },
  [DIALECTS.SLOVENIAN]: {
    textKey: 'ui.common.languages.slovenian_slovenia',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.SPANISH_MEXICO]: {
    textKey: 'ui.common.languages.spanish_mexico',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.SPANISH_SPAIN]: {
    textKey: 'ui.common.languages.spanish_spain',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.SWEDISH]: {
    textKey: 'ui.common.languages.swedish_sweden',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.TURKISH]: {
    textKey: 'ui.common.languages.turkish_turkey',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: true,
  },
  [DIALECTS.UKRAINIAN]: {
    textKey: 'ui.common.languages.ukrainian_ukraine',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: false,
  },
  [DIALECTS.VIETNAMESE]: {
    textKey: 'ui.common.languages.vietnamese_vietnam',
    pronouns: PRONOUNS_EXCLUSIVE,
    enabled: false,
  },
};

export const MODEL_UI_SUPPORTED_LOCALE = (
  model: Evaluator | undefined,
): LocalDetails[] => {
  const evaluatorMap: { [key in Evaluator]?: string } = {
    DISCOVERY: 'DISCOVERY',
    DFC: 'DFC',
    EXPLORE: 'DDDRS',
    DDDRS: 'DDDRS',
  };

  const evaluatorKey = model && evaluatorMap[model];

  return LOCALES.all.filter(
    (locale) =>
      locale.enabled &&
      (!evaluatorKey || locale.evaluator.includes(<Evaluator>evaluatorKey)),
  );
};

// ISO-639-2 mapping for Wistia
export const baseDialectLanguageCodes = [
  { language: 'Ifrikaans', code1: DIALECTS.AFRIKAANS, code2: 'afr' },
  { language: 'Bahassa', code1: DIALECTS.BAHASA, code2: 'ind' },
  { language: 'Bulgarian', code1: DIALECTS.BULGARIAN, code2: 'bul' },
  { language: 'Chinese', code1: DIALECTS.CHINESE_SIMPLIFIED, code2: 'chi' },
  { language: 'Chinese', code1: DIALECTS.CHINESE_TRADITIONAL_HK, code2: 'chi' },
  { language: 'Croatian', code1: DIALECTS.CROATIAN, code2: 'hrv' },
  { language: 'Czech', code1: DIALECTS.CZECH, code2: 'cze' },
  { language: 'Danish', code1: DIALECTS.DANISH, code2: 'dan' },
  { language: 'Dutch', code1: DIALECTS.DUTCH, code2: 'nld' },
  { language: 'English', code1: DIALECTS.ENGLISH, code2: 'eng' },
  { language: 'English', code1: DIALECTS.ENGLISH_SOUTH_AFRICA, code2: 'eng' },
  { language: 'Estonian', code1: DIALECTS.ESTONIAN, code2: 'est' },
  { language: 'Finnish', code1: DIALECTS.FINNISH, code2: 'fin' },
  { language: 'French', code1: DIALECTS.FRENCH, code2: 'fre' },
  { language: 'French', code1: DIALECTS.FRENCH_CANADA, code2: 'fre' },
  { language: 'Georgian', code1: DIALECTS.GEORGIAN, code2: 'geo' },
  { language: 'German', code1: DIALECTS.GERMAN, code2: 'deu' },
  { language: 'Greek', code1: DIALECTS.GREEK, code2: 'gre' },
  { language: 'Hungarian', code1: DIALECTS.HUNGARIAN, code2: 'hun' },
  { language: 'Icelandic', code1: DIALECTS.ICELANDIC, code2: 'ice' },
  { language: 'Italian', code1: DIALECTS.ITALIAN, code2: 'ita' },
  { language: 'Japenese', code1: DIALECTS.JAPANESE, code2: 'jpn' },
  { language: 'Korean', code1: DIALECTS.KOREAN, code2: 'kor' },
  { language: 'Lithuanian', code1: DIALECTS.LITHUANIAN, code2: 'lit' },
  { language: 'Norwegian', code1: DIALECTS.NORWEGIAN_BOKMAL, code2: 'nob' },
  { language: 'Polish', code1: DIALECTS.POLISH, code2: 'pol' },
  { language: 'Portuguese', code1: DIALECTS.PORTUGUESE_PORTUGAL, code2: 'por' },
  { language: 'Portuguese', code1: DIALECTS.PORTUGUESE_BRAZIL, code2: 'por' },
  { language: 'Romanian', code1: DIALECTS.ROMANIAN, code2: 'rum' },
  { language: 'Russian', code1: DIALECTS.RUSSIAN, code2: 'rus' },
  { language: 'Serbian', code1: DIALECTS.SERBIAN_LATIN, code2: 'srp' },
  { language: 'Slovakian', code1: DIALECTS.SLOVAKIANL, code2: 'slo' },
  { language: 'Slovenian', code1: DIALECTS.SLOVENIAN, code2: 'slv' },
  { language: 'Spanish', code1: DIALECTS.SPANISH_SPAIN, code2: 'spa' },
  { language: 'Spanish', code1: DIALECTS.SPANISH_MEXICO, code2: 'spa' },
  { language: 'Turkish', code1: DIALECTS.TURKISH, code2: 'tur' },
  { language: 'Ukranian', code1: DIALECTS.UKRAINIAN, code2: 'ukr' },
  { language: 'Vietnamese', code1: DIALECTS.VIETNAMESE, code2: 'vie' },
];

export const getCaptionLanguageCode = (code: string, dialects: string) => {
  let captionLanguageCode = code;
  const dialectsChosen = typesOfDialects[dialects];
  const dialectLanguageCodes = baseDialectLanguageCodes.filter((languageCode) =>
    Object.values(dialectsChosen).includes(
      languageCode.code1 as Extract<
        Dialects,
        (typeof PREFERRED_DIALECTS)[number]
      >,
    ),
  );

  const codeExists = dialectLanguageCodes.some(
    (element) => element.code1 === code,
  );

  if (!codeExists) {
    captionLanguageCode = DIALECTS.ENGLISH;
  }

  return dialectLanguageCodes[
    dialectLanguageCodes.findIndex(
      (captionLanguage) => captionLanguage.code1 === captionLanguageCode,
    )
  ].code2;
};
