import { useQuery } from '@tanstack/react-query';
import { useBetaEnabled } from 'features';
import * as api from './authentication';
import { GetTokenDetailsResponse } from './authentication';

const BETA_DFC_MAGIC_TOKEN = 'beta-dfc-token-details';

export const useGetTokenDetails = (token?: string, dialect?: string) => {
  const dfcDummyTokenDetailsEnabled =
    useBetaEnabled('DFC') && token === BETA_DFC_MAGIC_TOKEN;
  return useQuery<GetTokenDetailsResponse | false, Error>(
    ['tokenDetails', token],
    async () => {
      if (dfcDummyTokenDetailsEnabled) {
        return {
          model: 'DFC',
          evaluatorId: 'DUMMY_DFC_EVALUATOR_ID',
          learner: { fullName: 'Homer Simpson' },
        };
      }
      return api.getTokenDetails(token || '', dialect);
    },
    { enabled: Boolean(token) },
  );
};
