import { Evaluator, Question } from 'types/evaluator';
import { useQuery, useMutation, UseQueryOptions } from '@tanstack/react-query';
import { EvaluatorsResponse, Languages } from './evaluator';
import * as api from './evaluator';

export const useGetEvents = () => {
  return useQuery(['events'], api.getEvents);
};

export const useGetEvaluators = (
  eventId: string,
  config: UseQueryOptions<EvaluatorsResponse> = {},
) =>
  useQuery<EvaluatorsResponse>(
    ['evaluators', { eventId }],
    () => api.getEvaluators(eventId),
    config,
  );

export type EvaluatorDetails = {
  seed: number;
  dialect: string;
  pronoun: string;
  questions: Question[];
};

export const useGetEvaluatorLanguages = (
  languageSelectOption?: string,
  dialect?: string,
) =>
  useQuery<Languages>(['languages', languageSelectOption, dialect], async () =>
    api.getEvaluatorLanguages(languageSelectOption, dialect),
  );

export const useGetEvaluator = (model: Evaluator, dialect: string) =>
  useQuery<EvaluatorDetails>(['evaluator', { dialect }], async () => {
    if (model === 'DFC') {
      const { before, after, frames, ...rest } = await api.getEvaluator(
        model,
        dialect,
      );
      return {
        ...rest,
        questions: [
          ...before.map((b) => ({
            ...b,
            type: 'CHOICE' as const,
          })),
          ...frames.map((frame) => ({
            ...frame,
            type: 'FRAME' as const,
          })),
          ...after.map((a) => ({
            ...a,
            type: 'OPEN' as const,
          })),
        ],
      };
    }
    const { frames, ...rest } = await api.getEvaluator(model, dialect);
    return {
      ...rest,
      questions: frames.map((frame) => ({
        ...frame,
        type: 'FRAME' as const,
      })),
    };
  });

export const usePostEvaluator = () =>
  useMutation(
    ({
      model,
      evaluatorId,
      data,
      token,
    }: {
      model: Evaluator;
      evaluatorId: string;
      data: api.SubmitEvaluatorData;
      token?: string;
    }) => api.submitEvaluator(model, evaluatorId, data, token),
  );

export const useConfirmEmail = () =>
  useMutation(
    ({
      evaluatorLinkId,
      data,
    }: {
      evaluatorLinkId: string;
      data: api.VerifyEmailData;
    }) => api.verifyEmail(evaluatorLinkId, data),
  );

export const useVerifyEvaluatorlinkId = () => {
  return useMutation(({ evaluatorLinkId }: { evaluatorLinkId: string }) =>
    api.verifyEvaluatorlinkId(evaluatorLinkId),
  );
};
