import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@mui/material';
import { P } from '@insights-ltd/design-library';
import { Dialects, Pronoun } from 'types/dialects';
import { PRONOUNS_EXCLUSIVE, PROFILE_DIALECTS, PRONOUN_THEY } from 'variables';
import { useForm } from 'react-hook-form';
import { useGetEvaluatorLanguages } from 'api/evaluator.hooks';
import { Evaluator } from 'types/evaluator';
import { setFocusOnFirstError } from 'utils/formHelpers';
import { Select } from './FormInputs';
import FormLayout from './FormLayout';
import FormNavigation from './FormNavigation';
import { Step2Data } from './types';
import {
  StyledFieldWrapperEmulator,
  StyledPronounInfoWrapper,
  StyledInputWrapper,
} from './StyledComponents';

const Step2 = ({
  onNext,
  onBack,
  stepNumber,
  stepMax,
  userInfo,
  model,
}: {
  onNext: (data: Step2Data) => void;
  onBack?: () => void;
  stepNumber: number;
  stepMax: number;
  userInfo: Step2Data;
  model: Evaluator;
}) => {
  const { t, i18n } = useTranslation();

  const languageSelectOption = model === 'EXPLORE' ? 'EXPLORE' : 'ALL_DIALECTS';

  const { data: supportedDialects } = useGetEvaluatorLanguages(
    languageSelectOption,
    i18n.language,
  );

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    setFocus,
    formState: { errors },
  } = useForm<Step2Data>({
    defaultValues: userInfo,
  });

  const currentPronounsSelection = watch('pronoun') as Pronoun | '';
  const currentDialectSelection = watch('preferredDialect') as Dialects;

  useEffect(() => {
    if (
      PROFILE_DIALECTS[currentDialectSelection].pronouns ===
        PRONOUNS_EXCLUSIVE &&
      currentPronounsSelection === PRONOUN_THEY
    ) {
      setValue('pronoun', '');
    }
  }, [currentDialectSelection, currentPronounsSelection, setValue]);

  const activePronouns = PROFILE_DIALECTS[currentDialectSelection].pronouns;

  return (
    <FormLayout
      step={stepNumber}
      stepMax={stepMax}
      subtitle={t('ui.io.about_you.step_one.subtitle')}
      model={model}
    >
      <form
        onSubmit={handleSubmit(onNext, (submitErrors) => {
          setFocusOnFirstError(submitErrors, setFocus);
        })}
        noValidate
      >
        <StyledInputWrapper>
          <StyledFieldWrapperEmulator>
            {model === 'EXPLORE' && supportedDialects ? (
              <Select
                id="preferredDialect"
                name="preferredDialect"
                label={t('ui.io.demographics.languages.input_description')}
                control={control}
                autoComplete="language"
                error={Boolean(errors.preferredDialect)}
                errorMessage={t('ui.io.about_you.error.value_required')}
              >
                {supportedDialects.dialects.map((supportedDialect) => (
                  <MenuItem
                    key={supportedDialect.value}
                    value={supportedDialect.value}
                    disableRipple
                  >
                    {supportedDialect.textKey}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Select
                id="preferredDialect"
                name="preferredDialect"
                label={t('ui.io.demographics.languages.input_description')}
                control={control}
                error={Boolean(errors.preferredDialect)}
                errorMessage={t('ui.io.about_you.error.value_required')}
                autoComplete="language"
              >
                {Object.entries(PROFILE_DIALECTS)
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  .filter(([key, value]) => value.enabled)
                  .sort((a, b) =>
                    t(a[1].textKey).localeCompare(t(b[1].textKey)),
                  )
                  .map(([value, { textKey }]) => (
                    <MenuItem disableRipple key={value} value={value}>
                      {t(textKey)}
                    </MenuItem>
                  ))}
              </Select>
            )}
          </StyledFieldWrapperEmulator>
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Select
            id="pronoun"
            name="pronoun"
            label={t('ui.io.pronouns.input_description')}
            control={control}
            error={Boolean(errors.pronoun)}
            errorMessage={t('ui.io.about_you.error.pronoun_required')}
            autoComplete="sex"
          >
            <MenuItem disabled hidden value="" style={{ display: 'none' }}>
              {t('ui.io.about_you.please_select.placeholder')}
            </MenuItem>
            {activePronouns.map(({ textKey, value }) => (
              <MenuItem disableRipple key={value} value={value}>
                {t(textKey)}
              </MenuItem>
            ))}
          </Select>
        </StyledInputWrapper>
        <StyledPronounInfoWrapper>
          <P color="textPrimary" variant="body-bold">
            {t('ui.io.onboarding.pronoun_info.title')}
          </P>
          <P color="textSecondary">{t('ui.io.onboarding.pronoun_info.body')}</P>
        </StyledPronounInfoWrapper>
        <FormNavigation onBack={onBack} />
      </form>
    </FormLayout>
  );
};

export default Step2;
