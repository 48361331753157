import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, styled } from '@mui/material';
import { Tag } from '@insights-ltd/design-library';
import {
  INVALID,
  VALID,
  NEUTRAL,
  getValuesFromFormData,
  getOneLChipState,
  getOneMChipState,
  getTwoUniqueBetweenChipState,
  ERROR_ID_MAP,
} from './validationUtils';

const StyledTag = styled(Tag)({
  marginRight: '0.5rem',
  marginTop: '0.5rem',
  marginBottom: '1rem',
});

const ERROR_COLOR_MAP = {
  [INVALID]: 'red',
  [VALID]: 'darkGreen',
  [NEUTRAL]: 'grey',
} as const;

const StyledList = styled('ul')({
  listStyle: 'none',
  marginBlock: 0,
  paddingInline: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
});

const StyledListItem = styled('li')({
  display: 'inline',
});

const ValidationChips = ({
  formData,
  errors,
}: {
  formData: any;
  errors: any;
}) => {
  const { t } = useTranslation();
  const values = getValuesFromFormData(formData);
  const oneLState = getOneLChipState(values, errors);
  const oneMState = getOneMChipState(values, errors);
  const twoUniqueBetweenState = getTwoUniqueBetweenChipState(values, errors);

  return (
    <Grid container>
      <StyledList>
        <StyledListItem>
          <StyledTag
            id={ERROR_ID_MAP.ONE_MOST}
            aria-label={
              twoUniqueBetweenState === INVALID
                ? t('ui.io.evaluator.proposition.heading_m.aria-error')
                : undefined
            }
            label={t('ui.io.evaluator.validation_chip.one_m')}
            color={ERROR_COLOR_MAP[oneMState]}
            disabled={oneMState === NEUTRAL}
            role={oneMState === INVALID ? 'alert' : undefined}
          />
        </StyledListItem>
        <StyledListItem>
          <StyledTag
            id={ERROR_ID_MAP.ONE_LEAST}
            aria-label={
              twoUniqueBetweenState === INVALID
                ? t('ui.io.evaluator.proposition.heading_l.aria-error')
                : undefined
            }
            label={t('ui.io.evaluator.validation_chip.one_l')}
            color={ERROR_COLOR_MAP[oneLState]}
            disabled={oneLState === NEUTRAL}
            role={oneLState === INVALID ? 'alert' : undefined}
          />
        </StyledListItem>
        <StyledListItem>
          <StyledTag
            id={ERROR_ID_MAP.TWO_UNIQUE_BETWEEN}
            aria-label={
              twoUniqueBetweenState === INVALID
                ? t('ui.io.evaluator.proposition.heading_1-5.aria-error')
                : undefined
            }
            label={t('ui.io.evaluator.validation_chip.two_different')}
            color={ERROR_COLOR_MAP[twoUniqueBetweenState]}
            disabled={twoUniqueBetweenState === NEUTRAL}
            role={twoUniqueBetweenState === INVALID ? 'alert' : undefined}
          />
        </StyledListItem>
      </StyledList>
    </Grid>
  );
};

export default ValidationChips;
