import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import useAboveMobile from 'components/hooks/useAboveMobile';
import { ButtonV2 } from '@insights-ltd/design-library/src/components/ButtonV2';

const FormNavigation = ({ onBack }: { onBack?: () => void }) => {
  const { t } = useTranslation();
  const aboveMobile = useAboveMobile();

  const formButtonStyles = {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  };

  return (
    <Grid container justifyContent="space-between">
      <ButtonV2
        disableRipple
        sx={{
          ...formButtonStyles,
          visibility: onBack ? 'visible' : 'hidden',
          border: '2px solid',
        }}
        disabled={!onBack}
        onClick={onBack}
        buttonVariant="contained"
        buttonColour="secondary"
        size={aboveMobile ? 'large' : 'medium'}
        aria-hidden={!onBack}
        buttonText={t('ui.io.navigation.back')}
      />
      <ButtonV2
        disableRipple
        type="submit"
        sx={{
          ...formButtonStyles,
        }}
        variant="contained"
        color="primary"
        size={aboveMobile ? 'large' : 'medium'}
        buttonText={t('ui.io.navigation.next')}
      />
    </Grid>
  );
};

export default FormNavigation;
