import React, { useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { useGetTokenDetails } from 'api/authentication.hooks';
import { FullScreenSpinner } from 'components/FullScreen';
import useApplyQueryDialect from 'components/hooks/useApplyQueryDialect';
import {
  Evaluator as EvaluatorType,
  ExploreProfileResponse,
} from 'types/evaluator';
import { DATADOG_RUM_CONFIGS, LOCALES } from 'variables';
import { FieldValues } from 'react-hook-form';
import { Dialects } from 'types/dialects';
import { datadogRum } from '@datadog/browser-rum';
import Welcome from './Welcome';
import { Step1, Step2, Step3, Step4, StepDFC, UserInfoForm } from './AboutYou';
import Instructions from './Instructions';
import EvaluatorContent from './EvaluatorContent';
import Thanks from './Thanks';

const INITIAL_USER_INFO_STATE: UserInfoForm = {
  fullName: '',
  knownAs: '',
  referralCode: '',
  pronoun: '',
  preferredDialect: LOCALES.default,
  countryOfResidence: '',
  age: '',
  jobTitle: 'n/a',
  lengthOfService: '',
  jobLevel: '',
  sector: '',
  subSector: '',
};

function shouldMonitor() {
  return (
    import.meta.env.PROD &&
    DATADOG_RUM_CONFIGS.find(
      (config) => config.hostname === window.location.host,
    )
  );
}

const Evaluator = () => {
  useApplyQueryDialect();
  const { hash, pathname, search } = useLocation();
  const dialect: Dialects = new URLSearchParams(search).get(
    'dialect',
  ) as Dialects;

  const params = useParams();
  const navigate = useNavigate();
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [userInfo, setUserInfo] = useState<UserInfoForm>(
    INITIAL_USER_INFO_STATE,
  );
  const token = hash.match(/#token=(.+)/)?.[1];
  const searchParams = new URLSearchParams(search);
  const noSignUp = Boolean(token);
  const { data: tokenDetails } = useGetTokenDetails(token, dialect);
  const { model, evaluatorId } = token
    ? tokenDetails || { model: undefined, evaluatorId: undefined }
    : {
        model: searchParams.get('model') as EvaluatorType,
        evaluatorId: params.evaluatorId,
      };

  const [mutateResponse, setMutateResponse] = useState<ExploreProfileResponse>(
    {},
  );

  const subjectFullName =
    tokenDetails && tokenDetails.model === 'DFC'
      ? tokenDetails.learner.fullName
      : undefined;

  React.useEffect(() => {
    if (shouldMonitor() && tokenDetails) {
      datadogRum.setUser({ id: token });
    }
  }, [token, tokenDetails]);

  const completeWelcome = () => {
    setPrivacyPolicyAccepted(true);
    navigate(`about-you-1${search}${hash}`);
  };

  let steps: any[];
  if (model === 'DFC') {
    steps = [StepDFC];
  } else {
    steps = noSignUp ? [Step1, Step2, Step3, Step4] : [Step2, Step3, Step4];
  }

  const completeStep = (data: FieldValues) => {
    setUserInfo((state) => ({ ...state, ...{ ...data } }));
    const currentStep = Number(pathname.match(/about-you-(.+)/)?.[1] || 1);
    if (currentStep === steps.length) {
      navigate(`instructions${search}${hash}`);
    } else {
      navigate(`about-you-${currentStep + 1}${search}${hash}`);
    }
  };

  const completeInstructions = () => navigate(`questions${search}${hash}`);
  const completeEvaluator = () =>
    navigate(`evaluator-complete${search}${hash}`);

  if (noSignUp && tokenDetails === undefined) {
    return <FullScreenSpinner />;
  }

  if ((noSignUp && tokenDetails === false) || !model || !evaluatorId) {
    return <Navigate to="/tokenerror" replace />;
  }

  return (
    <Routes>
      <Route
        path="welcome"
        element={
          <Welcome
            noSignUp={noSignUp}
            privacyPolicyAccepted={privacyPolicyAccepted}
            onComplete={completeWelcome}
            subjectFullName={subjectFullName}
            model={model}
          />
        }
      />
      {privacyPolicyAccepted && (
        <>
          {steps.map((Step: any, index) => (
            <Route
              path={`about-you-${index + 1}`}
              key={steps.indexOf(Step)}
              element={
                <Step
                  onNext={completeStep}
                  stepNumber={index + 1}
                  stepMax={steps.length}
                  userInfo={userInfo}
                  onBack={index > 0 ? () => navigate(-1) : undefined}
                  model={model}
                />
              }
            />
          ))}
          <Route
            path="instructions"
            element={
              <Instructions
                onNext={completeInstructions}
                model={model}
                subjectFullName={subjectFullName}
              />
            }
          />
          <Route
            path="questions"
            element={
              <EvaluatorContent
                token={token}
                evaluatorId={evaluatorId || ''}
                userInfo={userInfo}
                evaluatorModel={model}
                onComplete={completeEvaluator}
                subjectFullName={subjectFullName}
                responseMutation={setMutateResponse}
              />
            }
          />
          <Route
            path="evaluator-complete"
            element={
              <Thanks
                noSignUp={noSignUp}
                model={model}
                mutateResponse={mutateResponse}
              />
            }
          />
        </>
      )}
      {!privacyPolicyAccepted && (
        <Route path="*" element={<Navigate to={`welcome${search}${hash}`} />} />
      )}
    </Routes>
  );
};

export default Evaluator;
