import React from 'react';
import { Box, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import Logo from 'components/Svgs/InsightsLogo';
import LanguageSelector from 'components/LanguageSelector/LanguageSelector';
import { H1 } from '@insights-ltd/design-library';

type HeaderProps = {
  title?: string;
  languageSelect?: boolean;
};

const DefaultHeader = ({ title, languageSelect = true }: HeaderProps) => {
  const mediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md'),
  );
  const theme = useTheme();

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'grey.400' }}>
      <Stack
        translate="no"
        direction="row"
        height="4.5rem"
        justifyContent="space-between"
        alignItems="center"
        px={4}
      >
        <Logo
          data-testid="logo-svg"
          fill={theme.palette.blue.main}
          height="2rem"
          title="Insights Logo"
          aria-label="Insights"
        />
        <div>
          {mediumScreen && title && <H1 variant="body-bold">{title}</H1>}
        </div>
        <Box minWidth="10rem">
          {languageSelect ? <LanguageSelector /> : null}
        </Box>
      </Stack>
    </Box>
  );
};

export default DefaultHeader;
