import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Menu, MenuItem, styled } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Translate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MODEL_UI_SUPPORTED_LOCALE } from 'variables';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Evaluator } from 'types/evaluator';
import { useGetTokenDetails } from 'api/authentication.hooks';
import { useGetEvaluatorLanguages } from '../../api/evaluator.hooks';

const StyledLanguageIcon = styled(LanguageIcon)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const StyledSpan = styled('span')(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const { hash } = useLocation();
  const token = hash.match(/#token=(.+)/)?.[1];
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: tokenDetails } = useGetTokenDetails(token, i18n.language);
  const model = token
    ? (tokenDetails || { model: undefined }).model
    : (searchParams.get('model') as Evaluator);
  const { pathname } = useLocation();
  const isExploreDigitalProfile = pathname.includes('exploredigitalProfile');

  const languageSelectOption = isExploreDigitalProfile
    ? 'EXPLORE'
    : 'ALL_DIALECTS';

  const { data: supportedDialects } = useGetEvaluatorLanguages(
    languageSelectOption,
    i18n.language,
  );

  useEffect(() => {
    document.documentElement.setAttribute('lang', i18n.language.split('-')[0]);
  }, [i18n.language]);

  const [languageMenu, setLanguageMenu] = useState<HTMLElement | null>(null);

  const handleLanguageMenuClose = async (language: string | null = null) => {
    if (language) {
      if (isExploreDigitalProfile) {
        setSearchParams({ dialect: language });
      }
      await i18n.changeLanguage(language);
    }
    setLanguageMenu(null);
  };

  const selectLanguage = MODEL_UI_SUPPORTED_LOCALE(model).find(
    (locale) => locale.enabled && locale.tag === i18n.language,
  )?.text;

  const languageOptions = MODEL_UI_SUPPORTED_LOCALE(model);

  return (
    <>
      <Button
        aria-owns={languageMenu ? 'language-menu' : undefined}
        aria-haspopup="true"
        aria-label={t('ui.io.language.change-language')}
        onClick={(e) => setLanguageMenu(e.currentTarget)}
        data-testid="language-selector"
      >
        <StyledLanguageIcon />
        &nbsp;
        <StyledSpan>{selectLanguage}</StyledSpan>
        <ExpandMoreIcon fontSize="small" />
      </Button>
      <Menu
        id="language-menu"
        anchorEl={languageMenu}
        open={Boolean(languageMenu)}
        onClose={() => handleLanguageMenuClose()}
      >
        {isExploreDigitalProfile && supportedDialects
          ? supportedDialects.dialects.map((language) => (
              <MenuItem
                disableRipple
                key={language.value}
                selected={i18n.language === language.value}
                onClick={() => handleLanguageMenuClose(language.value)}
              >
                {language.textKey}
              </MenuItem>
            ))
          : languageOptions.map((language) => (
              <MenuItem
                disableRipple
                key={language.tag}
                selected={i18n.language === language.tag}
                onClick={() => handleLanguageMenuClose(language.tag)}
              >
                {language.text}
              </MenuItem>
            ))}
      </Menu>
    </>
  );
};

export default LanguageSelector;
