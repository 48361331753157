import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, styled } from '@mui/material';
import privacyPolicyURL from 'utils/privacyPolicy';

const RequiredAsterisk = styled('span')(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const PrivacyPolicyCheckboxLabel = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Trans t={t} i18nKey="ui.io.onboarding.non_sign_up.copyright_notice">
        <Link
          href={privacyPolicyURL(i18n.language)}
          target="_blank"
          rel="noopener"
        >
          ui.io.onboarding.non_sign_up.copyright_notice
        </Link>
      </Trans>
      <RequiredAsterisk aria-hidden="true">*</RequiredAsterisk>
    </>
  );
};

export default PrivacyPolicyCheckboxLabel;
