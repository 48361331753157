import React from 'react';
import { Grid, Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import {
  ProductCard,
  ProductCardProps,
} from '@insights-ltd/design-library/src/components/ProductCard';
import { P } from '@insights-ltd/design-library';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ThemesCardLearn from '../assets/themes-learn.jpg';
import ThemesCardRemote from '../assets/themes-remote-working.jpg';
import ThemesCardWellbeing from '../assets/themes-wellbeing.jpg';
import ThemesCardService from '../assets/themes-service.jpg';

const StyledThemesIntro = styled(P)(({ theme }) => ({
  margin: `${theme.spacing(2)} 0`,
  maxWidth: '720px',
}));

type ProductCardRouteProp = {
  themeRoute: string;
};

type CombinedProps = ProductCardProps & ProductCardRouteProp;

const SectionThemes = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const productCards = [
    {
      title: t(
        'ui.io.explore.evaluator.digital_profile.themes.learn_card_title',
      ),
      content: t(
        'ui.io.explore.evaluator.digital_profile.themes.learn_card_content',
      ),
      imageAlt: t(
        'ui.io.explore.evaluator.digital_profile.themes.learn_card_title',
      ),
      themeRoute: 'learn',
      image: ThemesCardLearn,
    },
    {
      title: t(
        'ui.io.explore.evaluator.digital_profile.themes.remote_card_title',
      ),
      content: t(
        'ui.io.explore.evaluator.digital_profile.themes.remote_card_content',
      ),
      imageAlt: t(
        'ui.io.explore.evaluator.digital_profile.themes.remote_card_title',
      ),
      themeRoute: 'remote-working',
      image: ThemesCardRemote,
    },
    {
      title: t(
        'ui.io.explore.evaluator.digital_profile.themes.wellbeing_card_title',
      ),
      content: t(
        'ui.io.explore.evaluator.digital_profile.themes.wellbeing_card_content',
      ),
      imageAlt: t(
        'ui.io.explore.evaluator.digital_profile.themes.wellbeing_card_title',
      ),
      themeRoute: 'wellbeing',
      image: ThemesCardWellbeing,
    },
    {
      title: t(
        'ui.io.explore.evaluator.digital_profile.themes.service_card_title',
      ),
      content: t(
        'ui.io.explore.evaluator.digital_profile.themes.service_card_content',
      ),
      imageAlt: t(
        'ui.io.explore.evaluator.digital_profile.themes.service_card_title',
      ),
      themeRoute: 'service',
      image: ThemesCardService,
    },
  ];

  const ProductCardsSection = (
    <>
      {productCards.map((product: CombinedProps) => (
        <Grid key={product.title} item>
          <ProductCard
            title={product.title}
            content={product.content}
            imageAlt={product.imageAlt}
            image={product.image}
            handleClick={() =>
              navigate({
                pathname: `${location.pathname}/${product.themeRoute}`,
                search: searchParams.toString(),
              })
            }
          />
        </Grid>
      ))}
    </>
  );

  return (
    <Box
      sx={(theme) => ({
        maxWidth: theme.breakpoints.values.lg,
        width: '100%',
      })}
      mb={(theme) => theme.spacing(spacingSizeMap.S)}
    >
      <h2>{t('ui.io.explore.evaluator.digital_profile.themes.header')}</h2>
      <StyledThemesIntro color="textSecondary">
        {t('ui.io.explore.evaluator.digital_profile.themes.header_content')}
      </StyledThemesIntro>
      <Grid
        container
        sx={(theme) => ({
          maxWidth: theme.breakpoints.values.lg,
          [theme.breakpoints.up('xs')]: {
            justifyContent: 'center',
          },
          [theme.breakpoints.up('sm')]: {
            justifyContent: 'center',
          },
          [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
          },
          [theme.breakpoints.up('lg')]: {
            justifyContent: 'space-between',
          },
        })}
        columnSpacing={{ xs: 3, sm: 2, lg: 1 }}
        rowSpacing={{ xs: 3, sm: 3, lg: 3 }}
      >
        {ProductCardsSection}
      </Grid>
    </Box>
  );
};

export default SectionThemes;
