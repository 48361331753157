import { P, ColorProfileIcon } from '@insights-ltd/design-library';
import { spacingSizeMap } from '@insights-ltd/design-library/src/themes/getMuiTheme';
import { Box, styled } from '@mui/material';
import useAboveMobile from 'components/hooks/useAboveMobile';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Colour, ColourScoreWithPreference } from 'types/constants';
import { colourMapInverted } from 'variables';

const StyledSectionOverviewIntro = styled(P)(({ theme }) => ({
  margin: `${theme.spacing(spacingSizeMap.S)} 0`,
  maxWidth: '720px',
}));

const StyledOverviewContentWrapper = styled(P)(() => ({
  maxWidth: '720px',
}));

type ColourScoreWithPreferenceProps = {
  profileConscious: ColourScoreWithPreference;
};

const SectionOverview = ({
  profileConscious,
}: ColourScoreWithPreferenceProps) => {
  const { t } = useTranslation();
  const aboveMobile = useAboveMobile();

  const colourOrder: Colour[] = (
    Object.keys(profileConscious).filter(
      (key) => key !== 'preferred',
    ) as Colour[]
  ).sort((a, b) => profileConscious[b] - profileConscious[a]);
  const dominantEnergyName = colourMapInverted[colourOrder[0]];
  const dominantColourName = colourOrder[0];

  return (
    <Box
      sx={(theme) => ({
        margin: `${theme.spacing(spacingSizeMap.M)} 0 ${theme.spacing(
          spacingSizeMap.S,
        )} 0`,
        [theme.breakpoints.up('md')]: {
          margin: `${theme.spacing(spacingSizeMap.XL)} 0 ${theme.spacing(
            spacingSizeMap.M,
          )} 0`,
        },
      })}
      mb={(theme) => theme.spacing(spacingSizeMap.S)}
    >
      <h2>{t('ui.io.explore.evaluator.digital_profile.overview.heading')}</h2>
      <StyledSectionOverviewIntro color="textSecondary">
        {t(`ui.io.explore.evaluator.digital_profile.overview.intro`)}
      </StyledSectionOverviewIntro>
      <Box
        sx={(theme) => ({
          alignItems: 'center',
          border: `1px solid ${theme.palette.grey[500]}`,
          borderRadius: '4px',
          display: 'flex',
          flexDirection: 'column',
          flexShrink: '0',
          margin: `${theme.spacing(spacingSizeMap.XS)} 0 0 0`,
          minHeight: '368px',
        })}
      >
        <Box
          sx={(theme) => ({
            borderBottom: `1px solid ${theme.palette.grey[400]}`,
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
          })}
        >
          <Box
            sx={(theme) => ({
              margin: theme.spacing(spacingSizeMap.S),
              [theme.breakpoints.down('md')]: {
                marginBottom: '0',
              },
            })}
          >
            <ColorProfileIcon
              colourEnergy={dominantEnergyName}
              whiteBackgroundBorder
              iconSize={aboveMobile ? 64 : 48}
            />
          </Box>
          <Box
            sx={(theme) => ({
              width: 'auto',
              margin: 'auto 0',
              [theme.breakpoints.down('md')]: {
                width: '100%',
                margin: `-${theme.spacing(
                  spacingSizeMap.S,
                )} 0 0 ${theme.spacing(spacingSizeMap.S)}`,
              },
            })}
          >
            <h2>
              {t(
                `ui.io.explore.evaluator.digital_profile.overview.${dominantColourName}_heading`,
              )}
            </h2>
          </Box>
        </Box>
        <Box
          sx={(theme) => ({
            display: 'flex',
            padding: `${theme.spacing(spacingSizeMap.XS)} ${theme.spacing(
              spacingSizeMap.S,
            )} ${theme.spacing(spacingSizeMap.L)} ${theme.spacing(
              spacingSizeMap.S,
            )}`,
            width: '100%',
            [theme.breakpoints.up('md')]: {
              padding: `${theme.spacing(spacingSizeMap.M)} ${theme.spacing(
                spacingSizeMap.S,
              )} ${theme.spacing(spacingSizeMap.XL)} ${theme.spacing(
                spacingSizeMap.M,
              )}`,
            },
          })}
        >
          <StyledOverviewContentWrapper color="textSecondary">
            {t(
              `ui.io.explore.evaluator.digital_profile.overview.${dominantColourName}_description`,
            )}
          </StyledOverviewContentWrapper>
        </Box>
      </Box>
    </Box>
  );
};

export default SectionOverview;
