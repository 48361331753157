import {
  Evaluator as EvaluatorModelType,
  ChoiceQuestion,
  OpenQuestion,
  Frame,
} from 'types/evaluator';
import { Dialects } from 'types/dialects';
import { authenticatedFetch, unauthenticatedFetch } from './fetch';

export type RequestErrorBody = {
  errors: { code: string }[];
  message: string;
  status: number;
  ts: string;
};

export class RequestError extends Error {
  readonly errorCodes: string[];

  status: number;

  getRequestErrorKeys = (
    defaultKey: string,
    httpCodeKeys: Record<number, string> = {},
    errorCodeKeys: Record<string, string> = {},
  ) => {
    const genericError = httpCodeKeys[this.status]
      ? [httpCodeKeys[this.status]]
      : null;
    const specificErrorStrings = this.errorCodes.reduce(
      (accumulator: string[], currCode: string) => {
        if (errorCodeKeys[currCode]) {
          accumulator.push(errorCodeKeys[currCode]);
        }
        return accumulator;
      },
      [],
    );
    if (specificErrorStrings && specificErrorStrings.length) {
      return specificErrorStrings;
    }
    if (genericError) {
      return genericError;
    }
    return [defaultKey];
  };

  constructor(message: string, responseBody: RequestErrorBody) {
    // 'Error' breaks prototype chain here in ES5
    super(message);
    // restore prototype chain
    Object.setPrototypeOf(this, RequestError.prototype);

    this.status = responseBody.status;
    this.errorCodes = responseBody.errors.map((codeObj) => codeObj.code);
  }
}

type RemoteLocation = {
  type: 'REMOTE';
  meetingLink: string;
  details: string;
};

type PhysicalLocation = {
  type: 'PHYSICAL';
  addressLine1: string;
  addressLine2: string;
  city: string;
  postcode: string;
  state: string;
  country: string;
};

export type EventLocation = RemoteLocation | PhysicalLocation;

export type Event = {
  id: string;
  name: string;
  dialect: string;
  startsAt: string;
  endsAt: string;
  deadlineAt: string;
  timezone: string;
  eventStatus: string;
  location: EventLocation;
};

export const getEvents = async (): Promise<{ events: Event[] }> => {
  const response = await authenticatedFetch('/api/v1/learners/me/events');

  if (!response.ok) {
    throw Error(response.status.toString());
  }

  return response.json();
};
export type Evaluator = { model: EvaluatorModelType; complete: boolean };
export type EvaluatorsResponse = Evaluator[];
export const getEvaluators = async (
  eventId?: string,
): Promise<EvaluatorsResponse> => {
  const response = await authenticatedFetch(
    `/api/v1/evaluator-requests/${eventId}`,
  );

  if (!response.ok) {
    throw Error(response.status.toString());
  }

  return response.json();
};

export type DDDRSEvaluatorResponse = {
  seed: number;
  dialect: string;
  pronoun: string;
  frames: Omit<Frame, 'type'>[];
};

export type DfcEvaluatorResponse = DDDRSEvaluatorResponse & {
  before: Omit<ChoiceQuestion, 'type'>[];
  after: Omit<OpenQuestion, 'type'>[];
};

export type EvaluatorResponse<Model extends EvaluatorModelType> =
  Model extends 'DFC' ? DfcEvaluatorResponse : DDDRSEvaluatorResponse;

export const getEvaluator = async <Model extends EvaluatorModelType>(
  model: Model,
  dialect?: string,
): Promise<EvaluatorResponse<typeof model>> => {
  const queryParams = new URLSearchParams();
  if (dialect) {
    queryParams.set('dialect', dialect);
  }

  const response = await authenticatedFetch(
    `/api/v1/evaluators/${model}?${queryParams}`,
  );

  if (!response.ok) {
    throw Error(response.status.toString());
  }

  return response.json();
};

export type DialectKeyValue = {
  textKey: string;
  value: string;
};

export type Languages = {
  dialects: Array<DialectKeyValue>;
};

export const getEvaluatorLanguages = async (
  model?: string,
  dialect?: string,
): Promise<Languages> => {
  const query = dialect ? `?dialect=${dialect}` : '';
  const evaluatorModel = model || '';

  const response = await unauthenticatedFetch(
    `/api/v1/dialects/${evaluatorModel}${query}`,
  );

  if (!response.ok) {
    throw Error(response.status.toString());
  }

  return response.json();
};

type SubmitEvaluatorDataDDDRS = {
  seed: number;
  userInfo: {
    fullName: string;
    knownAs: string;
    preferredDialect: string;
    pronoun: string;
  };
  demographicData: {
    countryOfResidenceKey: string;
    ageKey: string;
    jobTitle: string;
    lengthOfServiceKey: string;
    levelOfPositionKey: string;
    industrySectorKey: string;
    industrySubSectorKey: string;
  };
  frames: {
    propositions: {
      key: string;
      rank: number;
    }[];
  }[];
  dialect: string;
};

type SubmitEvaluatorDataDFC = SubmitEvaluatorDataDDDRS & {
  before: {
    key: string;
    value: string;
  }[];
  after: {
    key: string;
    text: string;
  }[];
};

export type SubmitEvaluatorData =
  | SubmitEvaluatorDataDDDRS
  | SubmitEvaluatorDataDFC;

export const submitEvaluator = async (
  model: EvaluatorModelType,
  evaluatorId: string,
  data: SubmitEvaluatorData,
  token?: string,
): Promise<Response> => {
  const tokenPath = token ? `/${token}` : '';

  const pronoun = data.userInfo.pronoun ?? 'THEY';

  const addedPronoun = {
    ...data,
    userInfo: {
      ...data.userInfo,
      dialect: data.userInfo.preferredDialect,
      pronoun,
    },
  };

  const response = await authenticatedFetch(
    `/api/v1/evaluators/${model}/responses${tokenPath}?requestId=${evaluatorId}`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(addedPronoun),
    },
  );

  if (!response.ok) {
    throw Error(response.status.toString());
  }

  return response;
};

export type VerifyEmailData = {
  emailAddress: string;
  dialect?: Dialects;
};

export const verifyEmail = async (
  evaluatorLinkId: string,
  data: VerifyEmailData,
): Promise<void> => {
  const response = await unauthenticatedFetch(
    `/api/v1/evaluator-links/${evaluatorLinkId}/signup/verify`,
    {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
    },
  );

  if (!response.ok) {
    const err: RequestErrorBody = await response.json();
    throw new RequestError(response.status.toString(), err);
  }
};

export const verifyEvaluatorlinkId = async (id: string): Promise<boolean> => {
  const response = await unauthenticatedFetch(`/api/v1/evaluator-links/${id}`, {
    method: 'HEAD',
  });

  if (!response.ok) {
    const err: RequestErrorBody = await response.json();
    throw new RequestError(response.status.toString(), err);
  }

  return response.ok;
};
